import * as echarts from 'echarts';

import { Accordion, Col, Image, Row } from 'react-bootstrap';
import {
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import EChartsReactCore from 'echarts-for-react/lib/core';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Technologies from 'components/anopseo/common/Technologies';
import { getColor } from 'helpers/utils';

const SiteAnalysisDetailsOverview = ({ title, data }) => {
  const scoreColor =
    data?.score > 30 ? (data?.score > 70 ? 'success' : 'warning') : 'danger';

  const scoreChartOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(scoreColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: data?.score,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ],
        detail: {
          formatter: '{value}%'
        }
      }
    ]
  };

  const summaryOption = {
    series: [
      {
        name: 'Corrects',
        type: 'bar',
        stack: 'total',
        data: [
          data?.overview?.searchablesCount,
          data?.overview?.indexablesCount,
          data?.overview?.notDuplicatedsCount,
          data?.overview?.contentSufficientsCount,
          data?.overview?.compliantsCount,
          data?.overview?.notBrokensCount,
          data?.overview?.notRedirectedsCount
        ]
      },
      {
        name: 'Problèmes',
        type: 'bar',
        stack: 'total',
        data: [
          data?.analysisData?.length - data?.overview?.searchablesCount,
          data?.analysisData?.length - data?.overview?.indexablesCount,
          data?.analysisData?.length - data?.overview?.notDuplicatedsCount,
          data?.analysisData?.length - data?.overview?.contentSufficientsCount,
          data?.analysisData?.length - data?.overview?.compliantsCount,
          data?.analysisData?.length - data?.overview?.notBrokensCount,
          data?.analysisData?.length - data?.overview?.notRedirectedsCount
        ]
      }
    ],
    color: [getColor('success'), getColor('danger')],
    xAxis: {
      type: 'category',
      data: [
        'Exploration',
        'Indexation',
        'URLs Dupliquées',
        'Contenu',
        'Performance',
        'URLs brisées',
        'Redirections'
      ]
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };

  return (
    <>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <Flex alignItems={'center'} direction={'column'}>
              {data?.analysisData != null ? (
                <>
                  <Image
                    className="img-fluid"
                    src={data?.analysisData[0]?.data?.overview?.screenshotUrl}
                  />
                  <a
                    href={data?.analysisData[0]?.data?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center"
                  >
                    {data?.analysisData[0]?.data?.url}
                  </a>
                </>
              ) : (
                <></>
              )}
            </Flex>
            <Technologies technologies={data?.technologies} />
          </Col>
          <Col className="border-start" xl={9}>
            <Flex className="p-2 gap-2" direction={'column'}>
              <h2>{title}</h2>
              <p>
                Le Crawl parcours un groupe de pages et analyse les problèmes
                les plus pertinents afin de vous faciliter la correction.
                <br />
                Ci-dessous vous pouvez visualiser les erreurs prioritaires à
                traiter ainsi qu'un score définissant la qualité de votre site
                web.
              </p>
              <Accordion flush>
                {Object.keys(data?.overview?.seoWarningMessages || {}).length >
                  0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>SEO</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(data?.overview?.seoWarningMessages)?.map(
                        (message, index) => (
                          <p key={`seoWarningMessage${index}`}>
                            {message}&nbsp;&#40;
                            <strong>
                              {data?.overview?.seoWarningMessages[message]}
                            </strong>
                            &#41;
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.seoIssueMessages || {}).length >
                  0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>SEO</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(data?.overview?.seoIssueMessages)?.map(
                        (message, index) => (
                          <p key={`seoIssueMessage${index}`}>
                            {message}&nbsp;&#40;
                            <strong>
                              {data?.overview?.seoIssueMessages[message]}
                            </strong>
                            &#41;
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.contentWarningMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Contenu</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(data?.overview?.contentWarningMessages)?.map(
                        (message, index) => (
                          <p key={`contentWarningMessage${index}`}>
                            {message}&nbsp;&#40;
                            <strong>
                              {data?.overview?.contentWarningMessages[message]}
                            </strong>
                            &#41;
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.contentIssueMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Contenu</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(data?.overview?.contentIssueMessages)?.map(
                        (message, index) => (
                          <p key={`contentIssueMessage${index}`}>
                            {message}&nbsp;&#40;
                            <strong>
                              {data?.overview?.contentIssueMessages[message]}
                            </strong>
                            &#41;
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.techniqueWarningMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Technique</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(
                        data?.overview?.techniqueWarningMessages
                      )?.map((message, index) => (
                        <p key={`techniqueWarningMessage${index}`}>
                          {message}&nbsp;&#40;
                          <strong>
                            {data?.overview?.techniqueWarningMessages[message]}
                          </strong>
                          &#41;
                        </p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.techniqueIssueMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Technique</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(data?.overview?.techniqueIssueMessages)?.map(
                        (message, index) => (
                          <p key={`techniqueIssueMessage${index}`}>
                            {message}&nbsp;&#40;
                            <strong>
                              {data?.overview?.techniqueIssueMessages[message]}
                            </strong>
                            &#41;
                          </p>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.performanceWarningMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Performance</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(
                        data?.overview?.performanceWarningMessages
                      )?.map((message, index) => (
                        <p key={`performanceWarningMessage${index}`}>
                          {message}&nbsp;&#40;
                          <strong>
                            {
                              data?.overview?.performanceWarningMessages[
                                message
                              ]
                            }
                          </strong>
                          &#41;
                        </p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.performanceIssueMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Performance</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(
                        data?.overview?.performanceIssueMessages
                      )?.map((message, index) => (
                        <p key={`performanceIssueMessage${index}`}>
                          {message}&nbsp;&#40;
                          <strong>
                            {data?.overview?.performanceIssueMessages[message]}
                          </strong>
                          &#41;
                        </p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.accessibilityWarningMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Accessibilité</span>
                        <FontAwesomeIcon
                          color={getColor('warning')}
                          icon={faExclamationCircle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(
                        data?.overview?.accessibilityWarningMessages
                      )?.map((message, index) => (
                        <p key={`accessibilityWarningMessage${index}`}>
                          {message}&nbsp;&#40;
                          <strong>
                            {
                              data?.overview?.accessibilityWarningMessages[
                                message
                              ]
                            }
                          </strong>
                          &#41;
                        </p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {Object.keys(data?.overview?.accessibilityIssueMessages || {})
                  .length > 0 && (
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <Flex className="gap-3" alignItems={'center'}>
                        <span>Accessibilité</span>
                        <FontAwesomeIcon
                          color={getColor('danger')}
                          icon={faExclamationTriangle}
                        />
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(
                        data?.overview?.accessibilityIssueMessages
                      )?.map((message, index) => (
                        <p key={`accessibilityIssueMessage${index}`}>
                          {message}&nbsp;&#40;
                          <strong>
                            {
                              data?.overview?.accessibilityIssueMessages[
                                message
                              ]
                            }
                          </strong>
                          &#41;
                        </p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="p-3 my-3">
        <Row>
          <Col xl={3}>
            <h3 className="fs-1 text-center">Score total</h3>
            <EChartsReactCore echarts={echarts} option={scoreChartOption} />
          </Col>
          <Col className="border-start" xl={9}>
            <h3 className="fs-1 text-center">Résumé</h3>
            <EChartsReactCore echarts={echarts} option={summaryOption} />
          </Col>
        </Row>
      </div>
    </>
  );
};

SiteAnalysisDetailsOverview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default SiteAnalysisDetailsOverview;
