import { Button, Card, Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { getColor } from 'helpers/utils';

const AvailableData = ({ title, dataCount, dataLimit, dataEnabled }) => {
  const usedDataPercentage = (dataCount * 100) / dataLimit;
  const progressBarVariant =
    usedDataPercentage > 30
      ? usedDataPercentage > 70
        ? 'danger'
        : 'warning'
      : 'success';

  return (
    <>
      <Row className="border-bottom py-2">
        <Col xl={6}>
          <Flex className="h-100" alignItems={'center'}>
            {title}
          </Flex>
        </Col>
        <Col xl={6}>
          <Flex
            className="h-100"
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
          >
            {dataLimit != undefined && (
              <>
                <p className="mb-0">
                  {dataCount} / {dataLimit}
                </p>
                <ProgressBar
                  now={usedDataPercentage}
                  style={{ width: '80px', height: '8px' }}
                  variant={progressBarVariant}
                />
              </>
            )}
            {dataEnabled != undefined && (
              <FontAwesomeIcon
                icon={`${dataEnabled ? 'check' : 'plus'}`}
                size="2x"
                color={`${
                  dataEnabled ? getColor('success') : getColor('danger')
                }`}
                style={{
                  transform: !dataEnabled && 'rotate(45deg)'
                }}
              />
            )}
          </Flex>
        </Col>
      </Row>
    </>
  );
};

AvailableData.propTypes = {
  title: PropTypes.string,
  dataCount: PropTypes.number,
  dataLimit: PropTypes.number,
  dataEnabled: PropTypes.bool
};

const InvoiceList = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const [user, setUser] = useState({});
  const [plans, setPlans] = useState([]);
  const [isFetchFinished, setIsFetchFinished] = useState(false);

  const fetchUser = useCallback(async () => {
    const userResponse = await fetch(
      `${process.env.REACT_APP_API_URL}users/me`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await userResponse.json();
    setUser(json.user);
  }, []);

  const fetchPlans = useCallback(async () => {
    const plansResponse = await fetch(`${process.env.REACT_APP_API_URL}plans`, {
      headers: { Authorization: 'Bearer ' + token }
    });
    const json = await plansResponse.json();
    setPlans(json.plans);
  }, []);

  useEffect(() => {
    setIsFetchFinished(false);
    fetchUser();
    fetchPlans();
    setIsFetchFinished(true);
  }, [fetchUser, fetchPlans]);

  return (
    <>
      <Row className="my-3 p-3">
        <div className="app-list-header">
          <h2 className="app-list-title">Liste de factures</h2>
        </div>
        <Col xl={8}>
          {user?.organization?.invoices?.length > 0 ? (
            <ul>
              {user?.organization?.invoices?.map((invoice, index) => (
                <li key={`organizationInvoice${index}`}>
                  <a href={invoice.url}>
                    {new Date(invoice.date).toLocaleDateString()}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucune facture disponible</p>
          )}
        </Col>
        <Col xl={4}>
          <h3 className="app-font-bold app-card-title fs-1">Consommation</h3>
          {isFetchFinished ? (
            <>
              <AvailableData
                title={'Analyse de Page'}
                dataCount={user?.organization?.apiUsage?.analyzes}
                dataLimit={user?.organization?.plan?.analyzes}
              />
              <AvailableData
                title={'Crawl Website'}
                dataCount={user?.organization?.apiUsage?.crawls}
                dataLimit={user?.organization?.plan?.crawls}
              />
              <AvailableData
                title={'Check du Backlink'}
                dataCount={user?.organization?.apiUsage?.backlinks}
                dataLimit={user?.organization?.plan?.backlinks}
              />
              <AvailableData
                title={'Seo Benchmark'}
                dataCount={user?.organization?.apiUsage?.benchmarks}
                dataLimit={user?.organization?.plan?.benchmarks}
              />
              <AvailableData
                title={'Suivi de position'}
                dataCount={user?.organization?.apiUsage?.trackings}
                dataLimit={user?.organization?.plan?.trackings}
              />
              <AvailableData
                title={'Social Buzz'}
                dataCount={user?.organization?.apiUsage?.socials}
                dataLimit={user?.organization?.plan?.socials}
              />
              <AvailableData
                title={'Moniteur'}
                dataCount={user?.organization?.apiUsage?.monitors}
                dataLimit={user?.organization?.plan?.monitors}
              />
              <AvailableData
                title={'Writer'}
                dataCount={user?.organization?.apiUsage?.writers}
                dataLimit={user?.organization?.plan?.writers}
              />
              <AvailableData
                title={'Rapport'}
                dataCount={user?.organization?.apiUsage?.reports}
                dataLimit={user?.organization?.plan?.reports}
              />
              <AvailableData
                title={'Utilisateurs'}
                dataCount={user?.organization?.apiUsage?.users}
                dataLimit={user?.organization?.plan?.users}
              />
              <AvailableData
                title={'Support par ticket/email'}
                dataEnabled={user?.organization?.plan?.ticketemail}
              />
              <AvailableData
                title={'Support par téléphone'}
                dataEnabled={user?.organization?.plan?.telsupport}
              />
              <AvailableData
                title={'Référenceur dédié'}
                dataEnabled={user?.organization?.plan?.dedicatedref}
              />
              <AvailableData
                title={'API'}
                dataEnabled={user?.organization?.plan?.api}
              />
            </>
          ) : (
            <Flex justifyContent={'center'}>
              <Spinner className="my-3" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Flex>
          )}
        </Col>
      </Row>
      <Card className="my-3 p-3">
        {isFetchFinished ? (
          <Row className="p-2">
            {plans?.map(plan => (
              <Col
                md={6}
                xl={3}
                className={classNames('border-top border-bottom', {
                  'dark__bg-1000 px-4 px-lg-0':
                    plan.productId == user?.organization?.plan?.productId
                })}
                style={{
                  backgroundColor:
                    plan.productId == user?.organization?.plan?.productId &&
                    'rgba(115, 255, 236, 0.18)'
                }}
                key={plan.id}
              >
                <div className="h-100">
                  <div className="text-center p-4">
                    <h2 className="fw-normal my-0 text-uppercase">
                      {plan.name}
                    </h2>
                    <h3 className="fw-medium my-4">
                      {plan.price / 100}
                      <sup className="fw-medium fs-1 me-1">€</sup>
                      <small className="fs--1 text-700">/ mois</small>
                    </h3>
                    <Button
                      as={Link}
                      variant={
                        plan.productId == user?.organization?.plan?.productId
                          ? 'outline-primary'
                          : 'primary'
                      }
                      disabled={
                        plan.productId == user?.organization?.plan?.productId
                      }
                      className={
                        plan.productId == user?.organization?.plan?.productId &&
                        'disabled'
                      }
                      to={`${plan.url}?prefilled_email=${user?.email}`}
                    >
                      {plan.productId == user?.organization?.plan?.productId
                        ? 'Actif'
                        : 'Souscrire'}
                    </Button>
                  </div>
                  <hr className="border-bottom-0 m-0" />
                  <div className="text-start px-sm-4 py-4">
                    <ul className="list-unstyled mt-3">
                      <li className="py-1">
                        <strong>{plan.analyzes}</strong> Analyses
                      </li>
                      <li className="py-1">
                        <strong>{plan.crawls}</strong> Crawls
                      </li>
                      <li className="py-1">
                        <strong>{plan.backlinks}</strong> Backlinks
                      </li>
                      <li className="py-1">
                        <strong>{plan.benchmarks}</strong> Benchmarks
                      </li>
                      <li className="py-1">
                        <strong>{plan.trackings}</strong> Suivis
                      </li>
                      <li className="py-1">
                        <strong>{plan.socials}</strong> Social Buzz
                      </li>
                      <li className="py-1">
                        <strong>{plan.monitors}</strong> Moniteurs
                      </li>
                      <li className="py-1">
                        <strong>{plan.writers}</strong> Writers
                      </li>
                      <li className="py-1">
                        <strong>{plan.reports}</strong> Rapports
                      </li>
                      <li className="py-1">
                        <strong>{plan.users}</strong> Utilisateurs
                      </li>
                      <li className="py-1">
                        <FontAwesomeIcon
                          icon={`${plan.ticketemail ? 'check' : 'plus'}`}
                          color={`${
                            plan.ticketemail
                              ? getColor('success')
                              : getColor('danger')
                          }`}
                          style={{
                            transform: !plan.ticketemail && 'rotate(45deg)'
                          }}
                        />{' '}
                        Support par ticket/email
                      </li>
                      <li className="py-1">
                        <FontAwesomeIcon
                          icon={`${plan.telsupport ? 'check' : 'plus'}`}
                          color={`${
                            plan.telsupport
                              ? getColor('success')
                              : getColor('danger')
                          }`}
                          style={{
                            transform: !plan.telsupport && 'rotate(45deg)'
                          }}
                        />{' '}
                        Support par téléphone
                      </li>
                      <li className="py-1">
                        <FontAwesomeIcon
                          icon={`${plan.dedicatedref ? 'check' : 'plus'}`}
                          color={`${
                            plan.dedicatedref
                              ? getColor('success')
                              : getColor('danger')
                          }`}
                          style={{
                            transform: !plan.dedicatedref && 'rotate(45deg)'
                          }}
                        />{' '}
                        Référenceur dédié
                      </li>
                      <li className="py-1">
                        <FontAwesomeIcon
                          icon={`${plan.api ? 'check' : 'plus'}`}
                          color={`${
                            plan.api ? getColor('success') : getColor('danger')
                          }`}
                          style={{
                            transform: !plan.api && 'rotate(45deg)'
                          }}
                        />{' '}
                        API
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <Flex justifyContent={'center'}>
            <Spinner className="my-3" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Flex>
        )}
      </Card>
    </>
  );
};

export default InvoiceList;
