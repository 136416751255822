import BubbleChartLineIcon from 'remixicon-react/BubbleChartLineIcon';
import BugLineIcon from 'remixicon-react/BugLineIcon';
import ChatQuoteLineIcon from 'remixicon-react/ChatQuoteLineIcon';
import FlagLineIcon from 'remixicon-react/FlagLineIcon';
import Home2LineIcon from 'remixicon-react/Home2LineIcon';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import LineChartLineIcon from 'remixicon-react/LineChartLineIcon';
import LinksLineIcon from 'remixicon-react/LinksLineIcon';
import LogoutBoxLineIcon from 'remixicon-react/LogoutBoxLineIcon';
import React from 'react';
import SearchEyeLineIcon from 'remixicon-react/SearchEyeLineIcon';
import Settings3LineIcon from 'remixicon-react/Settings3LineIcon';

export const anopseoRoutes = {
  label: 'Anopseo',
  labelDisable: true,
  children: [
    {
      name: 'Tableau de Bord',
      icon: <Home2LineIcon />,
      to: '/',
      active: true
    },
    {
      name: 'Analyse de Page',
      icon: <SearchEyeLineIcon />,
      to: '/page-analysis',
      active: true
    },
    {
      name: 'Crawl Website',
      icon: <BugLineIcon />,
      to: '/site-analysis',
      active: true
    },
    {
      name: 'Check du Backlink',
      icon: <LinksLineIcon />,
      to: '/backlink',
      active: true
    },
    {
      name: 'Seo Benchmark',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M8 3C4.13401 3 1 6.13401 1 10C1 13.866 4.13401 17 8 17H9.07089C9.02417 16.6734 9 16.3395 9 16C9 15.6605 9.02417 15.3266 9.07089 15H8C5.23858 15 3 12.7614 3 10C3 7.23858 5.23858 5 8 5H16C18.7614 5 21 7.23858 21 10C21 10.3428 20.9655 10.6775 20.8998 11.0008C21.4853 11.5748 21.9704 12.2508 22.3264 13C22.7583 12.0907 23 11.0736 23 10C23 6.13401 19.866 3 16 3H8ZM16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13ZM11 16C11 13.2386 13.2386 11 16 11C18.7614 11 21 13.2386 21 16C21 17.0191 20.6951 17.967 20.1716 18.7574L22.7071 21.2929L21.2929 22.7071L18.7574 20.1716C17.967 20.6951 17.0191 21 16 21C13.2386 21 11 18.7614 11 16Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      to: '/benchmark',
      active: true
    },
    {
      name: 'Suivi de Position',
      icon: <LineChartLineIcon />,
      to: '/tracking',
      active: true
    },
    {
      name: 'Social Buzz',
      icon: <BubbleChartLineIcon />,
      to: '/social',
      active: true
    },
    {
      name: 'Moniteur',
      icon: <InformationLineIcon />,
      to: '/monitor',
      active: true
    },
    {
      name: 'Writer',
      icon: <ChatQuoteLineIcon />,
      to: '/writer'
    },
    {
      name: 'Rapport',
      icon: <FlagLineIcon />,
      to: '/report',
      active: true
    },
    {
      name: 'Réglages',
      icon: <Settings3LineIcon />,
      active: true,
      children: [
        {
          name: 'Mon Profil',
          to: '/my-profile',
          active: true
        },
        {
          name: 'Support',
          to: '/support',
          active: true
        },
        {
          name: 'Facturation',
          to: '/invoice-list',
          active: true
        },
        {
          name: 'Utilisateurs',
          to: '/user-list',
          active: true
        }
      ]
    },
    {
      name: 'Déconnexion',
      to: '#!',
      icon: <LogoutBoxLineIcon />,
      active: true,
      isLogout: true
    }
  ]
};

export default [anopseoRoutes];
